import React from "react"
import Image from "gatsby-image"

function card(props) {
  const { cardTitle, cardBody, cardImage, cardURL } = props
  return (
    <article className="flex flex-wrap mb-16 lg:mb-20">
      <div className="flex-initial p-4 lg:p-8  w-1/2 md:w-1/3 lg:w-1/4 ">
        <a
          className="block transition hover:zoom"
          href={cardURL}
          rel="noopener noreferrer"
          target="_blank"
          title={cardTitle}
        >
          <Image fluid={cardImage} />
        </a>
      </div>
      <div className="flex-initial w-full md:w-2/3 lg:w-3/4 md:px-8 lg:px-20">
        <h2 className="text-2xl lg:text-4xl font-serif mb-2">{cardTitle}</h2>
        <p className="text-sm md:text-base leading-loose mb-4 lg:mb-6 text-gray-700">
          {cardBody}
        </p>
        <p>
          <a
            href={cardURL}
            target="_blank"
            rel="noopener noreferrer"
            title={cardTitle}
            className="bg-primary transition hover:bg-primary-dark text-white py-2 px-4 lg:px-8 rounded-lg"
          >
            Visit {cardTitle}
          </a>
        </p>
      </div>
    </article>
  )
}

export default card
