import React from "react"
import "../style.css"
import Appbar from "../components/appbar"
import Card from "../components/card"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "../components/footer"

function Projects({ data }) {
  const { family, bizware, justtrees, mrtoolpro, music } = useFamilyPetLover()

  return (
    <div className="container-fluid">
      <Appbar />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Projects | Zey Ventures</title>
      </Helmet>
      <div className="row">
        <div className="bg-secondary-light ">
          <div className="container mx-auto py-8 px-4 lg:py-16">
            <h1
              itemProp="headline"
              className="max-w-4xl mb-4 lg:mb-6 text-3xl lg:text-5xl font-serif"
            >
              Our Brands
            </h1>

            <div className="text-gray-800 lg:w-3/5">
              <p className="text-sm md:text-base leading-loose mb-4 lg:mb-6">
                Our collection of review and blog websites continues to expand
                to cover a variety of industries and consumer interests. Ranging
                in topic from budget-friendly petcare to the best trees to plant
                for privacy, we have a large target audience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="container mx-auto px-4 py-8 lg:py-16">
        <Card
          cardTitle="Just Trees Online"
          cardURL="https://justtreesonline.com"
          cardBody="An informational resource covering everything you need to know about planting trees. Target audience is a homeowner looking for a quick and easy resource to learn more about what type of trees to plant in their zone."
          cardImage={justtrees.childImageSharp.fluid}
        />
        <Card
          cardTitle="Family Pet Lover"
          cardURL="https://familypetlover.com"
          cardBody="A casual-friendly blog dedicated to family pets and their
              individual needs. Categories include budget-friendly, pet bedding,
              toys, and training items."
          cardImage={family.childImageSharp.fluid}
        />
        <Card
          cardTitle="Just BizWare"
          cardURL="https://justbizware.com"
          cardBody="Informative reviews covering software for small business owners, written by small business owners."
          cardImage={bizware.childImageSharp.fluid}
        />
        <Card
          cardTitle="Top Musical Instruments"
          cardURL="https://thetopmusicalinstruments.com"
          cardBody="Primarily reviews and lists of instruments for beginners."
          cardImage={music.childImageSharp.fluid}
        />
        <Card
          cardTitle="Mr. ToolPro"
          cardURL="https://mrtoolpro.com"
          cardBody="For the homeowner handyman."
          cardImage={mrtoolpro.childImageSharp.fluid}
        />
      </section>
      <Footer />
    </div>
  )
}

export default Projects

export const useFamilyPetLover = () => {
  const { family, bizware, justtrees, mrtoolpro, music } = useStaticQuery(
    graphql`
      {
        family: file(relativePath: { eq: "family-pet-lover.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bizware: file(relativePath: { eq: "just-bizware.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        justtrees: file(relativePath: { eq: "just-trees-online.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mrtoolpro: file(relativePath: { eq: "mr-toolpro.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        music: file(relativePath: { eq: "top-musical-instruments.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return { family, bizware, justtrees, mrtoolpro, music }
}
